define("comunidades-nafin/components/competencias/evaluacion-usuario/aplicacion/template-evaluacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    evaluacion_porcentaje_avance: 0,
    evaluacionData: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('evaluacion_porcentaje_avance', this.evaluacion_porcentaje_avance);
      this.set('evaluacionData', this.evaluacionData);
      var self = this;
      setTimeout(self.actualizarGrafica, 1000, self);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupEmberChart();
      var welcome_message = '';
      this.set('welcome_message', '');
      welcome_message = window.translate("comunidades.comunidad_especifica.msg.msg_mensaje_bienvenida_comunidad.valor", '¡Bienvenid@ @userData.nombre@!');

      if (welcome_message.trim() != '') {
        welcome_message = welcome_message.replace('@userData.nombre@', this.session.userData.nombre);
        this.set('welcome_message', welcome_message);
      }
      /* Cargamos mensaje de Introduccion de acuerdo a la empresa */


      var self = this,
          evaluacion_titulo = "<p><b>" + welcome_message + "</b></p>",

      /* evaluacion_introduccion = 
          "<p>A continuación, encontrarás diferentes categorías con competencias para evaluar a cada uno de tus compañeros que te aparecerán, para lo que te pedimos contestes con honestidad ya que tu evaluación ayudará a cada colaborador a detectar sus fortalezas, así como, en todo lo que debe trabajar para mejorar cada competencia.</p>";  */
      evaluacion_introduccion = "<p>Como parte de nuestros procesos, hemos implementado una evaluación de clima laboral, la cual nos permitirá conocer elementos para mejorar como empresa.</p>" + "<p>Has sido seleccionado como evaluador de algunos colaboradores, por lo cual te pedimos que leas atentamente cada una de las categorías y competencias.<br />Te pedimos que contestes con honestidad de acuerdo a las condiciones en las que actualmente laboras, tu participación es muy importante. </p>";
      /* Datos por default */

      this.set('evaluacion_titulo', evaluacion_titulo);
      this.set('evaluacion_introduccion', ""); //this.set( 'evaluacion_introduccion', evaluacion_introduccion );

      $.ajax({
        url: constant.APIURL + 'usuario/evaluacionIntroduccion',
        type: "post",
        async: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: "json",
        success: function success(response) {
          if (response.numFilas == '1') {
            var aux_titulo = response.data[0].clima_evaluacion_introduccion_titulo;
            aux_titulo = aux_titulo.replace('@nombre_usuario@', self.session.userData.nombre);
            self.set('evaluacion_titulo', aux_titulo);
            self.set('evaluacion_introduccion', response.data[0].clima_evaluacion_introduccion);
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actualizarGrafica: function actualizarGrafica(scope) {
      var datasets = scope.get('avanceData.datasets');
      var data = datasets[0].data;
      var avance = scope.get('evaluacion_porcentaje_avance');
      datasets[0].data[0] = avance;
      datasets[0].data[1] = 100 - avance;
      scope.set('avanceData.datasets', datasets);
      var labels = scope.get('avanceData.labels');
      labels = [avance + '% completado', parseInt(100 - avance) + '% pendiente'];
      scope.set('avanceData', {
        datasets: [{
          data: [avance, 100 - avance],
          backgroundColor: ['#38bc62', '#dddddd'],
          borderWidth: 0
        }],
        labels: [avance + '% completado', parseInt(100 - avance) + '% pendiente']
      });
    },
    setupEmberChart: function setupEmberChart() {
      var formattAvance = this.evaluacion_porcentaje_avance;
      var avanceData = {
        datasets: [{
          data: [formattAvance, 100 - formattAvance],
          backgroundColor: ['#38bc62', '#dddddd'],
          borderWidth: 0
        }],
        labels: [formattAvance + '% completado', parseInt(100 - formattAvance) + '% pendiente']
      };
      this.set('avanceData', avanceData);
      this.set('chartype', 'doughnut');
      this.set('chartoptions', {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              var label = data.labels[tooltipItem.index] || '';
              return ' ' + label + ' ';
            }
          }
        }
      });
    }
  });

  _exports.default = _default;
});