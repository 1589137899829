define("comunidades-nafin/routes/asignacion-de-guias/administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    params: {},
    _controller: null,
    headers: [{
      dataIndex: 'seleccionar',
      title: '<input type="checkbox" id="chk_seleccionar_todo" />'
    }, {
      dataIndex: 'usuario_k',
      title: 'ID'
    }, {
      dataIndex: 'nombre_completo',
      title: 'Nombre'
    }, {
      dataIndex: 'email',
      title: 'Email'
    }, {
      dataIndex: 'datos_empresa',
      title: 'Datos de Empresa'
    }, // { dataIndex: 'rol', title: 'Rol(s)' },
    {
      dataIndex: 'periodo_nom035',
      title: 'Periodo NOM035'
    }, {
      dataIndex: 'guianom035',
      title: 'Guia NOM035'
    }, {
      dataIndex: 'estatus_guianom035',
      title: 'Estatus'
    }, // { dataIndex: 'periodo_nom037', title: 'Periodo NOM037' },
    {
      dataIndex: 'guianom037',
      title: 'Guia NOM037'
    }, {
      dataIndex: 'estatus_guianom037',
      title: 'Estatus'
    }],
    headers_recordatorios: [{
      dataIndex: 'recordatorio_k',
      title: 'ID'
    }, {
      dataIndex: 'guia',
      title: 'Guía'
    }, {
      dataIndex: 'total_usuarios',
      title: 'Total de usuarios'
    }, {
      dataIndex: 'fecha_recordatorio',
      title: 'Fecha programada'
    }, {
      dataIndex: 'estatus',
      title: 'Estatus'
    }, {
      dataIndex: 'fecha_emision',
      title: 'Fecha de notificación'
    }, {
      dataIndex: 'acciones',
      title: 'Acciones'
    }],
    headers_recordatorios_usuarios: [{
      dataIndex: 'nombre',
      title: 'Nombre'
    }, {
      dataIndex: 'apellido_paterno',
      title: 'Ap. Paterno'
    }, {
      dataIndex: 'apellido_materno',
      title: 'Ap. Materno'
    }, {
      dataIndex: 'email',
      title: 'Email'
    }],
    idiomaTable: {
      "decimal": "",
      "emptyTable": "Sin registros",
      "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
      "infoEmpty": "Mosrando 0 to 0 de 0 registros",
      "infoFiltered": "(Filtro _MAX_ registros totales)",
      "infoPostFix": "",
      "thousands": ",",
      "lengthMenu": "Mostrar _MENU_ registros",
      "loadingRecords": "Cargando...",
      "processing": "",
      "search": "Buscar:",
      "zeroRecords": "Sin coincidencias",
      "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
      },
      "aria": {
        "sortAscending": ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      }
    },
    centros: [],
    id_centro: null,
    config_table_recordatorios: null,
    beforeModel: function beforeModel() {
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        controller.set('headersTable', self.get("headers"));
        controller.set('headers_recordatorios', self.get("headers_recordatorios"));
        controller.set('centros', self.get("centros"));
        controller.set('recordatoriosTable', []);
        controller.set('headers_recordatorios_usuarios', self.get("headers_recordatorios_usuarios"));
        controller.set('recordatoriosusuariosTable', []);
        self.loadData(controller);
      }, 1000);
    },
    loadData: function loadData(controller) {
      var self = this;
      /* Carga de Usuarios */

      self.carga_de_usuarios(controller);
      /* Cargamos el Catalogo de Centros */

      self.obtener_centros(controller);
      $("#centros").on('change', function () {
        var centro = this.value;
        self.actualizar_tabla_usuarios(centro);
      });
      $('#dtpicker, #dtpicker037, #dtpicker_nom').datetimepicker({
        locale: 'es',
        format: 'L',
        ignoreReadonly: true // inline: true,
        // sideBySide: true

      });
      $('#dtpicker_notificacion').datetimepicker({
        locale: 'es',
        format: 'YYYY-MM-DD HH:mm',
        ignoreReadonly: true,
        // inline: true,
        sideBySide: true
      });
      $('#asignarGuiaModal').on('hidden.bs.modal', function (e) {
        $("input[name='rd_nom035']").prop('checked', false);
        $("#fecha_termino").val("");
      });
      $('#asignarGuia037Modal').on('hidden.bs.modal', function (e) {
        $("input[name='rd_nom037']").prop('checked', false);
        $("#fecha_termino_nom037").val("");
      });
      $('#recordatorioModal').on('hidden.bs.modal', function (e) {
        $("input[name='rd_nom']").prop('checked', false);
        $("#fecha_termino_nom").val("");
        $("#fecha_termino_notificacion").val("");
      });
    },
    actualizar_tabla_usuarios: function actualizar_tabla_usuarios() {
      var centro = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var self = this;
      $("#loading").show();
      self.config_table_usuarios.clear().draw();
      self.config_table_usuarios.destroy();
      self.carga_de_usuarios(self._controller, centro);
    },
    carga_de_usuarios: function carga_de_usuarios(controller) {
      var centro = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var self = this;
      var datos = new FormData();
      datos.append('centro', centro);
      datos.append('nom035_periodo_activo_k', window.constant.configuracionEmpresa.nom035_periodo_activo_k);
      datos.append('nom037_periodo_activo_k', window.constant.configuracionEmpresa.nom037_periodo_activo_k);
      $.ajax({
        url: constant.APIURL + 'asignacion_de_guias/administrador/listarusuarios',
        type: 'POST',
        data: datos,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('dataTable', response.data);
          setTimeout(function () {
            self.config_table_usuarios = $("#usuariosTable").DataTable({
              language: self.idiomaTable,
              retrieve: true,
              scrollY: '480px',
              scrollCollapse: true,
              paging: false,
              columnDefs: [{
                target: 0,
                orderable: false
              }, {
                target: 1,
                visible: false
              }, {
                target: 3,
                visible: false
              }],
              order: []
            });
            $("#chk_seleccionar_todo").on("click", function () {
              if ($(this).is(':checked')) {
                $('.chk_usuario').prop('checked', true);
              } else {
                $('.chk_usuario').prop('checked', false);
              }
            });
            $("#loading").fadeOut(500);
          }, 1000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    carga_de_recordatorios: function carga_de_recordatorios(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'asignacion_de_guias/administrador/listarrecordatorios',
        type: 'POST',
        data: {},
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('recordatoriosTable', response.data);
          setTimeout(function () {
            self.config_table_recordatorios = $("#recordatoriosTable").DataTable({
              language: self.idiomaTable,
              retrieve: true,
              scrollY: '480px',
              scrollCollapse: true,
              paging: false,
              order: [],
              columns: [null, null, {
                className: 'centrar_contenido'
              }, {
                className: 'centrar_contenido'
              }, {
                className: 'centrar_contenido'
              }, {
                className: 'centrar_contenido'
              }, {
                className: 'centrar_contenido'
              }]
            }); // Reenvio de contraseña

            $('#recordatoriosTable tbody').on('click', '.btn_vistausuarios', function (event) {
              // Obtenemos los datos de la fila seleccionada
              var row = $(this).closest('tr');
              var datos = self.config_table_recordatorios.row(row).data();
              self.vista_de_usuarios(controller, datos);
            }); // Reenvio de contraseña

            $('#recordatoriosTable tbody').on('click', '.btn_cancelarnotificacion', function (event) {
              if (confirm("¿Estas seguro de cancelar el envío de la notificación a los usuarios?")) {
                // Obtenemos los datos de la fila seleccionada
                var row = $(this).closest('tr');
                var datos = self.config_table_recordatorios.row(row).data();
                self.cancelar_recordatorio(controller, datos);
              }
            });
            $("#loading").fadeOut(500);
          }, 1000);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    vista_de_usuarios: function vista_de_usuarios(controller) {
      var datos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var recordatorio_k = datos[0];
      var parametros = new FormData();
      parametros.append('recordatorio_k', recordatorio_k);
      $.ajax({
        url: constant.APIURL + 'asignacion_de_guias/administrador/recordatorios_usuarios',
        type: 'POST',
        data: parametros,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          $('#recordatoriousuariosModal').modal('show');
          controller.set('recordatoriosusuariosTable', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    cancelar_recordatorio: function cancelar_recordatorio(controller) {
      var datos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var self = this;
      var recordatorio_k = datos[0];
      var parametros = new FormData();
      parametros.append('recordatorio_k', recordatorio_k);
      $.ajax({
        url: constant.APIURL + 'asignacion_de_guias/administrador/cancelar_recordatorio',
        type: 'POST',
        data: parametros,
        processData: false,
        contentType: false,
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.send("obtener_recordatorios");
          showModal({
            titulo: 'Cancelación',
            mensaje: 'Se canceló correctamente la notificación.'
          });
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    obtener_centros: function obtener_centros(controller) {
      $.ajax({
        url: constant.APIURL + 'asignacion_de_guias/administrador/obtener_centros',
        type: 'POST',
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set('centros', response.data);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    actions: {
      asignar_guia_nom035: function asignar_guia_nom035() {
        var usuarios = []; // Validar que se hayan seleccionado usuarios

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (usuarios.length === 0) {
          showModal({
            titulo: 'Asignación de Guías',
            mensaje: 'Selecciona a los usuarios que se les va asignar la Guía(s). '
          });
          return false;
        } // Mostrar Modal para asignar la Guia(s)


        $('#asignarGuiaModal').modal('show');
      },
      cerrar_asignar_guia_nom035: function cerrar_asignar_guia_nom035() {
        $('#asignarGuiaModal').modal('hide');
      },
      cerrar_asignar_guia_nom037: function cerrar_asignar_guia_nom037() {
        $('#asignarGuia037Modal').modal('hide');
      },
      guardar_asignar_guia_nom035: function guardar_asignar_guia_nom035() {
        var self = this;
        var usuarios = [];
        var datos = new FormData();
        var guia = $("input[name='rd_nom035']:checked").val();
        var fecha_termino = $("#fecha_termino").val(); // Obtenemos los usuarios seleccionados

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (typeof guia === "undefined") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Selecciona la guía correspondiente. '
          });
          return false;
        }

        if (fecha_termino.length == 0 || fecha_termino == "") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Agrega la fecha de termino de la evaluación. '
          });
          return false;
        }

        datos.append('usuarios', JSON.stringify(usuarios));
        datos.append('guia', guia);
        datos.append('fecha_termino', fecha_termino);
        datos.append('nom035_periodo_activo_k', window.constant.configuracionEmpresa.nom035_periodo_activo_k);
        $.ajax({
          url: constant.APIURL + 'asignacion_de_guias/administrador/asignar_guias',
          type: 'POST',
          data: datos,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          success: function success(response) {
            $('#asignarGuiaModal').modal('hide');
            var centro_seleccionado = $("#centros").val();
            self.actualizar_tabla_usuarios(centro_seleccionado);
            console.log('res: ', response);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      asignar_guia_nom037: function asignar_guia_nom037() {
        var usuarios = []; // Validar que se hayan seleccionado usuarios

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (usuarios.length === 0) {
          showModal({
            titulo: 'Asignación de Guías',
            mensaje: 'Selecciona a los usuarios que se les va asignar la Guía(s). '
          });
          return false;
        } // Mostrar Modal para asignar la Guia(s)


        $('#asignarGuia037Modal').modal('show');
      },
      guardar_asignar_guia_nom037: function guardar_asignar_guia_nom037() {
        var self = this;
        var usuarios = [];
        var datos = new FormData();
        var guia = $("input[name='rd_nom037']:checked").val();
        var fecha_termino = $("#fecha_termino_nom037").val(); // Obtenemos los usuarios seleccionados

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (typeof guia === "undefined") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Selecciona la guía correspondiente. '
          });
          return false;
        }

        if (fecha_termino.length == 0 || fecha_termino == "") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Agrega la fecha de termino de la evaluación. '
          });
          return false;
        }

        datos.append('usuarios', JSON.stringify(usuarios));
        datos.append('guia', guia);
        datos.append('fecha_termino', fecha_termino);
        datos.append('nom035_periodo_activo_k', 0); // No aplica para la NOM037

        $.ajax({
          url: constant.APIURL + 'asignacion_de_guias/administrador/asignar_guias',
          type: 'POST',
          data: datos,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          success: function success(response) {
            $('#asignarGuia037Modal').modal('hide');
            var centro_seleccionado = $("#centros").val();
            self.actualizar_tabla_usuarios(centro_seleccionado);
            console.log('res: ', response);
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      programar_recordatorio: function programar_recordatorio() {
        var usuarios = []; // Validar que se hayan seleccionado usuarios

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (usuarios.length === 0) {
          showModal({
            titulo: 'Recordatorio de Guías',
            mensaje: 'Selecciona a los usuarios que serán notificados para contestar la guia. '
          });
          return false;
        } // Mostrar Modal para asignar la Guia(s)


        $('#recordatorioModal').modal('show');
      },
      cerrar_asignar_guia_nom: function cerrar_asignar_guia_nom() {
        $('#recordatorioModal').modal('hide');
      },
      guardar_asignar_guia_nom: function guardar_asignar_guia_nom() {
        var self = this;
        var usuarios = [];
        var datos = new FormData();
        var guia = $("input[name='rd_nom']:checked").val();
        var fecha_termino_notificacion = $("#fecha_termino_notificacion").val();
        var fecha_termino = $("#fecha_termino_nom").val(); // Obtenemos los usuarios seleccionados

        $(".chk_usuario:checked").each(function () {
          usuarios.push(this.value);
        });

        if (typeof guia === "undefined") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Selecciona la guía correspondiente. '
          });
          return false;
        }

        if (fecha_termino.length == 0 || fecha_termino == "") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Agrega la fecha de termino de la evaluación. '
          });
          return false;
        }

        if (fecha_termino_notificacion.length == 0 || fecha_termino_notificacion == "") {
          showModal({
            titulo: 'Validación',
            mensaje: 'Agrega la fecha de notoficación. '
          });
          return false;
        }

        datos.append('usuarios', JSON.stringify(usuarios));
        datos.append('guia', guia);
        datos.append('fecha_termino', fecha_termino);
        datos.append('fecha_termino_notificacion', fecha_termino_notificacion);
        $.ajax({
          url: constant.APIURL + 'asignacion_de_guias/administrador/programar_notificacion',
          type: 'POST',
          data: datos,
          processData: false,
          contentType: false,
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          success: function success(response) {
            $('.chk_usuario').prop('checked', false);
            $('#chk_seleccionar_todo').prop('checked', false);
            $('#recordatorioModal').modal('hide');
            showModal({
              titulo: 'Recordatorio',
              mensaje: 'Programación guardada correctamente.'
            });
            return false;
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      obtener_recordatorios: function obtener_recordatorios() {
        var self = this;
        $("#loading").show();

        if (self.config_table_recordatorios != null) {
          self.config_table_recordatorios.clear().draw();
          self.config_table_recordatorios.destroy();
        }

        self.carga_de_recordatorios(self._controller);
      },
      cerrar_vista_usuarios: function cerrar_vista_usuarios() {
        $('#recordatoriousuariosModal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});