define("comunidades-nafin/routes/catalogo-cursos-administrador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    config_tabla_cursos: null,
    datos_tabla_cursos: null,
    nombre_curso: null,
    agregar_nuevo_scorm: null,
    idiomaTable: {
      "decimal": "",
      "emptyTable": "Sin registros",
      "info": "Mostrando del _START_ al  _END_ de _TOTAL_ Registros",
      "infoEmpty": "Mosrando 0 to 0 de 0 registros",
      "infoFiltered": "(Filtro _MAX_ registros totales)",
      "infoPostFix": "",
      "thousands": ",",
      "lengthMenu": "Mostrar _MENU_ registros",
      "loadingRecords": "Cargando...",
      "processing": "",
      "search": "Buscar:",
      "zeroRecords": "Sin coincidencias",
      "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
      },
      "aria": {
        "sortAscending": ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      }
    },
    beforeModel: function beforeModel() {
      if (!window.constant.BAND_RELOAD) {
        $("body").html("");
        window.location.reload();
      }
    },
    setupController: function setupController(controller) {
      var self = this;
      self._controller = controller;
      setTimeout(function () {
        self.loadData(controller);
        self.controller.set("nombre_curso", self.nombre_curso);
        self.controller.set("agregar_nuevo_scorm", true);
      });
    },
    loadData: function loadData(controller) {
      var self = this;
      self.obtener_catalogo_cursos(controller);
      $('#nuevoCursoModal').modal({
        show: false,
        keyboard: false,
        backdrop: 'static'
      }); // Modal: Nuevo Curso

      $('#nuevoCursoModal').on('hidden.bs.modal', function (e) {
        $("#frm_curso_nombre").val("");
        $("#frm_curso_descripcion").val("");
        $("#nuevoCursoModal").data("curso_k", 0);
        $("#title_nuevo_curso").html("Nuevo Curso");
        $("#btn_agregar_curso").html("Agregar Curso");
      });
      $('#nuevoCursoModal').on('shown.bs.modal', function () {
        $('#frm_curso_nombre').trigger('focus');
      }); // Modal: Scorm

      $('#scormCursoModal').on('hidden.bs.modal', function (e) {
        $("#scormCursoModal").data("curso_k", 0);
        $("#scormCursoModal").data("lms_scorm_k ", 0);
        $("#scormCursoModal").data("ruta_scorm	", "");
        $("#scormCursoModal").data("nombre_zip", "");
        $("#title_scormCursoModal").html("Agregar Scorm");
        controller.set("agregar_nuevo_scorm", true);
        $("#frm_curso_scorm").val(null);
        $("#elemento_loader").css({
          "visibility": "hidden"
        });
      }); // Configuración de los botones para las diferentes acciones

      $('#tabla_cursos tbody').on('click', '.btn_editarcurso', function (event) {
        // Obtenemos los datos de la fila seleccionada
        var row = $(this).closest('tr');
        var datos = self.config_tabla_cursos.row(row).data();
        self.obtener_datos_curso(datos);
      });
      $('#tabla_cursos tbody').on('click', '.btn_agregarscorm', function (event) {
        // Obtenemos los datos de la fila seleccionada
        var row = $(this).closest('tr');
        var datos = self.config_tabla_cursos.row(row).data();
        controller.set("agregar_nuevo_scorm", true);
        controller.set("nombre_curso", datos[1]);
        $("#title_scormCursoModal").html("Agregar Scorm");
        $("#scormCursoModal").data("accion", "agregar");
        $("#scormCursoModal").data("curso_k", datos[0]);
        $("#scormCursoModal").modal("show");
      });
      $('#tabla_cursos tbody').on('click', '.btn_reemplazarscorm', function (event) {
        // Obtenemos los datos de la fila seleccionada
        var row = $(this).closest('tr');
        var datos = self.config_tabla_cursos.row(row).data();
        var curso_k = datos[0];

        var registro_curso = self._controller.get("datos_tabla_cursos");

        var objeto_registro = registro_curso.find(function (obj) {
          return obj.curso_k == curso_k;
        });
        controller.set("agregar_nuevo_scorm", false);
        controller.set("nombre_curso", datos[1]);
        $("#title_scormCursoModal").html("Reemplazar Scorm");
        $("#scormCursoModal").data("accion", "reemplazar");
        $("#scormCursoModal").data("curso_k", curso_k);
        $("#scormCursoModal").data("lms_scorm_k", objeto_registro.lms_scorm_k);
        $("#scormCursoModal").data("ruta_scorm	", objeto_registro.ruta_scorm);
        $("#scormCursoModal").data("nombre_zip", objeto_registro.nombre_zip);
        $("#scormCursoModal").modal("show");
      });
      $('#tabla_cursos tbody').on('click', '.btn_vistaprevia', function (event) {
        // Obtenemos los datos de la fila seleccionada
        var row = $(this).closest('tr');
        var datos = self.config_tabla_cursos.row(row).data();
        self.vista_previa_scorm(datos);
      });
      $('#tabla_cursos tbody').on('click', '.btn_eliminarcurso', function (event) {
        // Obtenemos los datos de la fila seleccionada
        var row = $(this).closest('tr');
        var datos = self.config_tabla_cursos.row(row).data();
        controller.set("nombre_curso", datos[1]);
        $('#borrarCursoModal').data("curso_k", datos[0]);
        $('#borrarCursoModal').modal('show');
      });
    },
    vista_previa_scorm: function vista_previa_scorm() {
      var datos_curso = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var self = this;
      var curso_k = datos_curso[0];

      var registro_curso = self._controller.get("datos_tabla_cursos");

      var objeto_registro = registro_curso.find(function (obj) {
        return obj.curso_k == curso_k;
      });
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("target", "_blank");
      form.setAttribute("action", window.constant.BASERECURSOSURL + "index.php/scorm/lanzarscorm");
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "lms_scorm_k");
      hiddenField.setAttribute("value", objeto_registro.lms_scorm_k);
      form.appendChild(hiddenField);
      var hiddenField2 = document.createElement("input");
      hiddenField2.setAttribute("type", "hidden");
      hiddenField2.setAttribute("name", "scorm_test");
      hiddenField2.setAttribute("value", "1");
      form.appendChild(hiddenField2);
      var hiddenField3 = document.createElement("input");
      hiddenField3.setAttribute("type", "hidden");
      hiddenField3.setAttribute("name", "propietario_recurso_k");
      hiddenField3.setAttribute("value", objeto_registro.propietario_recurso_k);
      form.appendChild(hiddenField3);
      document.body.appendChild(form);
      form.submit();
    },
    obtener_catalogo_cursos: function obtener_catalogo_cursos(controller) {
      var self = this;
      $.ajax({
        url: constant.APIURL + 'lms/curso/listar',
        type: 'POST',
        data: {
          "limit": 1000
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json',
        success: function success(response) {
          controller.set("datos_tabla_cursos", response.data);
          setTimeout(function () {
            // Configuramos el datatable
            self.config_tabla_cursos = $("#tabla_cursos").DataTable({
              language: self.idiomaTable,
              retrieve: true,
              scrollY: '350px',
              scrollCollapse: true,
              paging: false,
              order: []
            });
            $("#loading").fadeOut(1000);
          }, 500);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    obtener_datos_curso: function obtener_datos_curso() {
      var datos_curso = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (datos_curso.length > 0) {
        var _self = this;

        var curso_k = datos_curso[0];
        var nombre = datos_curso[1];

        var registro_curso = _self._controller.get("datos_tabla_cursos");

        var objeto_registro = registro_curso.find(function (obj) {
          return obj.curso_k == curso_k;
        });
        var descripcion = objeto_registro.descripcion;
        $("#frm_curso_nombre").val(nombre);
        $("#frm_curso_descripcion").val(descripcion);
        $("#nuevoCursoModal").data("curso_k", curso_k);
        $("#title_nuevo_curso").html("Editar Curso");
        $("#btn_agregar_curso").html("Editar Curso");
        $('#nuevoCursoModal').modal('show');
      } else {
        showModal({
          titulo: 'Edición del Curso.',
          mensaje: 'Ocurrió un error al intentar editar el curso.'
        });
      }
    },
    actions: {
      cerrar_modal_curso: function cerrar_modal_curso() {
        $('#nuevoCursoModal').modal('hide');
      },
      agregar_curso: function agregar_curso() {
        var self = this;
        var nombre = $("#frm_curso_nombre").val().trim();
        var descripcion = $("#frm_curso_descripcion").val().trim();
        var curso_k = $("#nuevoCursoModal").data("curso_k");
        var url = curso_k > 0 ? 'editar' : 'agregar'; // Validamos campos

        $("#frm_curso_nombre, #frm_curso_descripcion").removeClass("is-invalid");

        if (nombre.length === 0 || nombre == "") {
          $("#frm_curso_nombre").addClass("is-invalid");
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Es necesario ingresar el Nombre del Curso.'
          });
          return false;
        }

        if (descripcion.length === 0 || descripcion == "") {
          $("#frm_curso_descripcion").addClass("is-invalid");
          showModal({
            titulo: 'Validación de campos.',
            mensaje: 'Es necesario ingresar la Descripción del Curso.'
          });
          return false;
        }

        $.ajax({
          url: constant.APIURL + 'lms/curso/' + url,
          type: 'POST',
          data: {
            "txt_nombre": nombre,
            "txt_descripcion": descripcion,
            "id_tipo": 1,
            "categoria_k": 59,
            "i": curso_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json',
          success: function success(response) {
            if (response.success) {
              $('#nuevoCursoModal').modal('hide');
              $("#loading").show();
              self.config_tabla_cursos.clear().draw().destroy();
              self.obtener_catalogo_cursos(self._controller);
              showModal({
                titulo: 'Nuevo Curso.',
                mensaje: 'Curso agregado correctamente.'
              });
            } else {
              showModal({
                titulo: 'Nuevo Curso.',
                mensaje: 'Ocurrió un error al intentar crear el curso.'
              });
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      eliminar_curso: function eliminar_curso() {
        var self = this;
        var curso_k = $('#borrarCursoModal').data("curso_k");
        $.ajax({
          url: constant.APIURL + 'lms/curso/eliminar',
          type: 'POST',
          data: {
            "i": curso_k
          },
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(response) {
            $('#borrarCursoModal').modal('hide');
            $("#loading").show();
            self.config_tabla_cursos.clear().draw().destroy();
            self.obtener_catalogo_cursos(self._controller);
            showModal({
              titulo: 'Eliminar Curso.',
              mensaje: 'Curso eliminado correctamente.'
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      reemplazar_scorm: function reemplazar_scorm() {
        var curso_k = $("#scormCursoModal").data("curso_k");
        var lms_scorm_k = $("#scormCursoModal").data("lms_scorm_k");
        var ruta_scorm = $("#scormCursoModal").data("ruta_scorm");
        var nombre_zip = $("#scormCursoModal").data("nombre_zip");
        var datos = new FormData();
        var archivo = $("#frm_curso_scorm")[0].files[0];

        if (typeof archivo !== 'undefined') {
          datos.append("datos", JSON.stringify({
            "curso_k": curso_k,
            "lms_scorm_k": lms_scorm_k,
            "ruta_scorm": ruta_scorm,
            "nombre_zip": nombre_zip
          }));
          datos.append("userfile", archivo);
          $("#btn_reemplazar_scorm").prop('disabled', true);
          $("#elemento_loader").css({
            "visibility": "visible"
          });
          $.ajax({
            url: constant.APIURL + 'lms/scorm/subirscorm',
            type: 'POST',
            data: datos,
            contentType: false,
            processData: false,
            xhrFields: {
              withCredentials: true
            },
            dataType: 'json',
            success: function success(response) {
              console.log(response);

              if (response.success) {
                $('#scormCursoModal').modal('hide');
                $("#loading").show();
                self.config_tabla_cursos.clear().draw().destroy();
                self.obtener_catalogo_cursos(self._controller);
                showModal({
                  titulo: 'Scorm.',
                  mensaje: 'Scorm agregado correctamente.'
                });
              } else {
                showModal({
                  titulo: 'Scorm.',
                  mensaje: response.error
                });
              }

              $("#btn_reemplazar_scorm").prop('disabled', false);
              $("#elemento_loader").css({
                "visibility": "hidden"
              });
            },
            error: function error(e) {
              console.log(e);
              $("#btn_reemplazar_scorm").prop('disabled', false);
              $("#elemento_loader").css({
                "visibility": "hidden"
              });
            }
          });
        } else {
          showModal({
            titulo: 'Scorm.',
            mensaje: 'Es necesario seleccionar un Scorm desde tu computadora.'
          });
        }
      },
      agregar_scorm: function agregar_scorm() {
        var curso_k = $("#scormCursoModal").data("curso_k");
        var datos = new FormData();
        var archivo = $("#frm_curso_scorm")[0].files[0];

        if (typeof archivo !== 'undefined') {
          datos.append("datos", JSON.stringify({
            "curso_k": curso_k
          }));
          datos.append("userfile", archivo);
          $("#btn_agregar_scorm").prop('disabled', true);
          $("#elemento_loader").css({
            "visibility": "visible"
          });
          $.ajax({
            url: constant.APIURL + 'lms/scorm/subirscorm',
            type: 'POST',
            data: datos,
            contentType: false,
            processData: false,
            xhrFields: {
              withCredentials: true
            },
            dataType: 'json',
            success: function success(response) {
              console.log(response);

              if (response.success) {
                $('#scormCursoModal').modal('hide');
                $("#loading").show();
                self.config_tabla_cursos.clear().draw().destroy();
                self.obtener_catalogo_cursos(self._controller);
                showModal({
                  titulo: 'Scorm.',
                  mensaje: 'Scorm agregado correctamente.'
                });
              } else {
                showModal({
                  titulo: 'Scorm.',
                  mensaje: response.error
                });
              }

              $("#btn_agregar_scorm").prop('disabled', false);
              $("#elemento_loader").css({
                "visibility": "hidden"
              });
            },
            error: function error(e) {
              console.log(e);
              $("#btn_agregar_scorm").prop('disabled', false);
              $("#elemento_loader").css({
                "visibility": "hidden"
              });
            }
          });
        } else {
          showModal({
            titulo: 'Scorm.',
            mensaje: 'Es necesario seleccionar un Scorm desde tu computadora.'
          });
        }
      }
    }
  });

  _exports.default = _default;
});